var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "c-table",
          {
            ref: "table",
            attrs: {
              title: "LBL0001108",
              columns: _vm.grid.columns,
              gridHeight: _vm.grid.height,
              data: _vm.process.thirdScenarios,
              columnSetting: false,
              expandAll: false,
              isFullScreen: false,
              usePaging: false,
              filtering: false,
              editable: _vm.editable && !_vm.disabled && _vm.isSelect,
              selection: "multiple",
              rowKey: "ramThirdAssessScenarioId",
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "picture"
                      ? [
                          _c("q-icon", {
                            staticClass: "text-success",
                            staticStyle: {
                              "font-size": "30px",
                              cursor: "pointer",
                            },
                            attrs: { name: "image" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openPicture(props)
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editable && !_vm.disabled && _vm.isSelect,
                          expression: "editable&&!disabled&&isSelect",
                        },
                      ],
                      attrs: { label: "LBLADD", icon: "add" },
                      on: { btnClicked: _vm.addRiskHazard },
                    }),
                    _c("c-btn", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.editable &&
                            !_vm.disabled &&
                            _vm.isSelect &&
                            _vm.process.thirdScenarios &&
                            _vm.process.thirdScenarios.length > 0,
                          expression:
                            "editable&&!disabled&&isSelect&&process.thirdScenarios&&process.thirdScenarios.length>0",
                        },
                      ],
                      attrs: { label: "LBLREMOVE", icon: "remove" },
                      on: { btnClicked: _vm.removeRiskHazard },
                    }),
                    _c("c-btn", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.editable &&
                            !_vm.disabled &&
                            _vm.isSelect &&
                            _vm.process.thirdScenarios &&
                            _vm.process.thirdScenarios.length > 0,
                          expression:
                            "editable&&!disabled&&isSelect&&process.thirdScenarios&&process.thirdScenarios.length>0",
                        },
                      ],
                      attrs: {
                        isSubmit: _vm.isSave,
                        url: _vm.saveUrl,
                        param: _vm.process.thirdScenarios,
                        mappingType: "PUT",
                        label: "LBLSAVE",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveRiskHazard,
                        btnCallback: _vm.saveRiskHazardCallback,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }